import * as React from "react"
import PageLayout from "../../components/pageLayout"
import { Select } from "antd"
import firebase from "firebase";
import { firebaseInit } from "../../firebaseConfig";

const {Option} = Select;


const SessionDetailPage = ({...props}) => {

    const sessionId = props.params.id
    const [logLevel,setLogLevel] = React.useState("info")
    const [session, setSession] = React.useState()


    React.useEffect(()=> {

        firebaseInit(firebase)        
        firebase.firestore().collection("crawlRegistry").doc(sessionId).get().then(queryResult => {

            if(queryResult.exists)
            {
                let data = queryResult.data()
                setSession(data);
            }
        })
    }, [])


    const LogEntry = ({entry}) => {

        let color = "#ffffff";
        let display = false;
        switch (entry.level)
        {
            case "info":
                color = "text-blue-500";
                display = logLevel === "debug" || logLevel ==="info"
                break;
            case "debug":
                color = "text-white";
                display = logLevel === "debug"
                break;
            case "error":
                color = "text-red-500"
                display = logLevel === "error" || logLevel === "debug"
                break;
            case "warning":
                color = "text-yellow-500";
                display = logLevel ==="warning" || logLevel === "debug"
            default:
                break;
        }

        return (<>{display ? <div className={`flex ${color} justify-between px-4 `}>
            
            <p>{entry.level}</p>
            <p>{entry.message}</p>

        </div> :null}</>)
    }

   return (<PageLayout>
       <h1>{sessionId}</h1>

       <Select defaultValue="info" value={logLevel}  onChange={(value)=>{setLogLevel(value)}}>
        <Option value="info">Info</Option>
        <Option value="debug">Debug</Option>
        <Option value="warning">Warning</Option>
        <Option value="error">Error</Option>
      </Select>

      <div className="w-full  bg-black mt-8 overflow-auto p-8">

          { session && session.log ?<>
            {session.log.map( entry => <LogEntry entry={entry} ></LogEntry>)}
          </>  :null}

      </div>
   </PageLayout>)
}


export default SessionDetailPage